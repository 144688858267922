<template>
  <div class="admin-feed-block ReportSubscriptionsCancelled " :class="{ loading: loading }">
    <Spacer num="1" />
    <Title :label="$locale['subscriptions_cancelled']" tcentered />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          v-for="(item, index) in items"
          :key="index"
          :label="`<div ellipsis><strong>@${item.user.user}</strong></div>`"
          :desc="`${item.user.name}`"
          :UserAvatar="item.user"
          :iconSize="`48px`"
          :rightDesc="
            `<div class='userListTotals'>
              <div class='totalBlock commission'>
                <div skeleton nowrap strong number>@${item.creator.user}</div>
                <small skeleton>${Go.date_locale(item.cancelRequest.date)}</small>
              </div>
            </div>`
          "
        >
          <div class="UserEmail">
            <div skeleton>
              {{ item.user.email }}
            </div>
          </div>
        </LargeButton>
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  data: function() {
    return {
      loading: false,
      store: Go.store("subscriptions_cancelled"),
      items: [],
      statistics: { total: 0, page: 1 },
    };
  },
  methods: {
    getStatistics: async function({ page = 1 } = {}) {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.query = Go.objectToQuery(this.$route.query);
        this.req = await this.api.get(`admin/admin-subscriptions-cancelled?${this.query}&page=${page}`);
        this.reqSuccess(this.req.data);
      } catch (error) {
        this.alert(Go.getErrorMessage(error));
      }

      this.isLoading(false);
    },
    reqSuccess: function({ statistics, items }) {
      if (statistics && Number(statistics.page) === 1) {
        this.store.set("statistics", statistics);
        this.store.set("items", items);
        this.statistics = statistics;
        this.$store.state.admin.salesByCreatorList.statistics = statistics;
        this.items = items;
        return;
      }

      this.statistics = statistics;
      this.items = [...this.items, ...items];
      this.store.set("statistics", statistics);
      this.store.set("items", this.items);
    },
  },
  mounted: function() {
    this.items = this.store.get("items") || [];
    this.statistics = this.store.get("statistics") || { totalItems: 0 };
    this.getStatistics({ page: 1 });
  },
};
</script>
